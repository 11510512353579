export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  mounted: function mounted() {
    var placeholderDiv = document.getElementById('vizContainer');
    var options = {
      showTabs: false,
      hideToolbar: true,
      hideTabs: true
    };
    var url = "http://52.81.198.57/trusted/F-CzW3fwT-iu-g1wikpQvw==:zrJC22U-DfpzjZGzWRbq_Sr4/views/Book1/RTMRTPDashboard?:iid=1?:embed=true&:toolbar=yes&:tabs=yes";
    // eslint-disable-next-line
    this.viz = new tableau.Viz(placeholderDiv, url, options);
  }
};